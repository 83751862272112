import { Load, LoadFilterType } from "@today/api/tracker"
import { partition } from "@today/lib"
import { useCallback, useEffect } from "react"
import { useSWRInfiniteWithAuth } from "./api-clients"

const PAGE_SIZE = 100

type ListLoadsResponse = {
  loads: Load[]
  nextPageToken?: string
  totalCount: number
}

export function useLoads(filters: LoadFilterType[]) {
  const {
    loads: unresolvedLoads,
    count: unresolvedLoadsCount,
    isLoading: isUnresolvedLoadsLoading,
    mutate: mutateUnresolvedLoads,
  } = useUnresolvedLoads(filters)
  const {
    loads: resolvedLoads,
    count: resolvedLoadsCount,
    isLoading: isResolvedLoadsLoading,
    mutate: mutateResolvedLoads,
  } = useResolvedLoads(filters)
  return {
    loads:
      unresolvedLoads || resolvedLoads
        ? [...(unresolvedLoads ?? []), ...(resolvedLoads ?? [])]
        : undefined,
    count:
      unresolvedLoadsCount !== undefined && resolvedLoadsCount !== undefined
        ? unresolvedLoadsCount + resolvedLoadsCount
        : undefined,
    isLoading: isUnresolvedLoadsLoading || isResolvedLoadsLoading,
    mutate: () => {
      mutateUnresolvedLoads()
      mutateResolvedLoads()
    },
  }
}

export function useUnresolvedLoads(filters: LoadFilterType[]) {
  return useLoadsInternal([...filters, "resolved=false"])
}

export function useResolvedLoads(filters: LoadFilterType[]) {
  return useLoadsInternal([...filters, "resolved=true"])
}

function useLoadsInternal(filters: LoadFilterType[]) {
  const [qFilters, cFilters] = partition(
    filters,
    (filter) => typeof filter === "string"
  )
  const queryFilters = qFilters! as string[]
  const clientFilters = cFilters! as ((load: Load) => boolean)[]
  const getSWRKey = useCallback(
    (pageIndex: number, previousPageData: ListLoadsResponse | null) => {
      // Reached the end
      if (previousPageData && !previousPageData.nextPageToken) return null
      const token = previousPageData?.nextPageToken
      const queryParams = new URLSearchParams({
        filter: queryFilters!.filter((f) => !!f).join(";"),
        page_size: PAGE_SIZE.toString(),
      })
      if (token) {
        queryParams.append("page_token", token)
      }
      return `/api/loads?${queryParams.toString()}`
    },
    [queryFilters]
  )
  const {
    data: loadsResponses,
    size,
    setSize,
    mutate,
  } = useSWRInfiniteWithAuth<ListLoadsResponse>(getSWRKey)
  const isLoading = size > 0 && !loadsResponses?.[size - 1]
  const firstPage = loadsResponses?.[0]
  useEffect(() => {
    if (firstPage) {
      setSize(Math.ceil(firstPage.totalCount / PAGE_SIZE))
    }
  }, [firstPage, setSize])
  const loads = loadsResponses
    ?.flatMap((resp) => resp.loads)
    .filter((load) => clientFilters.every((filter) => filter(load)))
  return {
    loads,
    count: loadsResponses ? firstPage?.totalCount ?? 0 : undefined,
    isLoading,
    mutate,
  }
}
